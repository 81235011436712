import React, { createContext, useContext, useEffect, useState, useCallback, useMemo } from 'react';
import { makeGetRequest } from "../apiRequests";
import { useNavigate } from "react-router-dom";
import { useAlert } from './AlertContext';
import PropTypes from 'prop-types';

const UserContext = createContext();

// context API for users, user sessions and login/logout processes
export const UserProvider = ({ children }) => {
    const [session, setSession] = useState(undefined);
    const [loginPage, setLoginPage] = useState(false);
    const { showAlert } = useAlert();
    const navigate = useNavigate();

    const loginUser = useCallback(async () => {
        try {
            const user = await makeGetRequest('/user');
            setLoginPage(false);
            setSession(user);
            navigate("/dashboard");
        } catch (error) {
            console.log(error);
            showAlert(error.toString());
        }
    }, [navigate, showAlert]);

    const logoutUser = useCallback(() => {
        setSession({});
        navigate("/");
    }, [navigate]);

    const toLoginPage = useCallback(() => {
        setLoginPage(true);
        navigate("/login");
    }, [navigate]);

    const returnToMain = useCallback(() => {
        setLoginPage(false);
        navigate("/");
    }, [navigate]);

    const onLoad = useCallback(async () => {
        try {
            const user = await makeGetRequest('/user');
            setSession(user);
        } catch (error) {
            console.log(error);
            showAlert(error.toString());
        }
    }, [showAlert]);

    useEffect(() => {
        onLoad();
    }, [onLoad]);

    const contextValue = useMemo(() => ({
        loginUser, logoutUser, toLoginPage, returnToMain, session, loginPage, setLoginPage
    }), [loginUser, logoutUser, toLoginPage, returnToMain, session, loginPage]);

    return (
        <UserContext.Provider value={contextValue}>
            {children}
        </UserContext.Provider>
    );
};

UserProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export const useUser = () => useContext(UserContext);
