import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { Typography, TTNewButton, TTNewRow, TTNewCol, TTNewCard, TTNewCardContent, Loader, Heading } from 'taltech-styleguide';
import { Container } from 'react-bootstrap';
import { makeGetRequest } from "../apiRequests";
import { useTranslation } from "react-i18next";
import { useAlert } from '../store/AlertContext';

const CourseDetail = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [course, setCourse] = useState([]);
    const [loading, setLoading] = useState(true);
    const { t } = useTranslation();
    const { showAlert } = useAlert();

    useEffect(() => {
        const courseId = location.pathname.split('/').pop();
        const makeRequest = async () => {
            try {
                const courseResponse = await makeGetRequest('/course/' + courseId);
                const course = await courseResponse.data;
                setCourse(course);
                console.log(course);
                setLoading(false);
            } catch (error) {
                showAlert(error.toString());
                setLoading(false);
            }
        };
        makeRequest();
    }, [location.pathname, showAlert]);

    // useNavigate function that navigates back to the previous page where user had been
    const returnBtn = () => {
        navigate(-1);
    }

    return (
        <Container>
            <TTNewButton
                className="mb-2"
                iconLeft="arrow_back"
                color="body-color"
                variant="tertiary"
                onClick={returnBtn}>
                {t('GENERAL.BACK')}
            </TTNewButton>
            <Heading className="mb-5"
                color="primary"
                visual="display-2">
                {t('DETAIL_VIEW.COURSE')}
            </Heading>
            {loading ? (
                <Loader center fullScreen size="md" />
            ) : (
                <TTNewRow>
                    <TTNewCol size={{
                        lg: 6,
                        sm: 12
                    }}>
                        <TTNewCard >
                            <TTNewCardContent>
                                <div className="mb-3">
                                    <Typography as="label" color="gray-700">Kursuse kood</Typography>
                                    <Typography className="fw-bolder" visual="body">{course.code}</Typography>
                                </div>
                                <div className="mb-3">
                                    <Typography as="label" color="gray-700">Kursuse nimetus (EST)</Typography>
                                    <Typography className="fw-bolder" visual="body">{course.name_est}</Typography>
                                </div>
                                <div className="mb-3">
                                    <Typography as="label" color="gray-700">Kursuse nimetus (ENG)</Typography>
                                    <Typography className="fw-bolder" visual="body">{course.name_eng}</Typography>
                                </div>
                                <div className="mb-3">
                                    <Typography as="label" color="gray-700">Semester</Typography>
                                    <Typography className="fw-bolder" visual="body">
                                        {t(`SEMESTER.${course.semester}`)}
                                    </Typography>
                                </div>
                                <div className="mb-3">
                                    <Typography as="label" color="gray-700">Kursuse keel</Typography>
                                    <Typography className="fw-bolder" visual="body">
                                        {t(`LANGUAGES.${course.language}`)}
                                    </Typography>
                                </div>
                                <div className="mb-3">
                                    <Typography as="label" color="gray-700">Kursuse maht</Typography>
                                    <Typography className="fw-bolder" visual="body">{course.volume} EAP</Typography>
                                </div>
                                <div className="mb-3">
                                    <Typography as="label" color="gray-700">Õppejõud</Typography>
                                    <Typography className="fw-bolder" visual="body">{course.lector}</Typography>
                                </div>
                            </TTNewCardContent>
                        </TTNewCard>
                    </TTNewCol>
                </TTNewRow>
            )}
        </Container >
    );
};

export default CourseDetail;