import React from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { useTranslation } from "react-i18next";

// reusable component that displays a popover with a message if the feature of a button is unavailable
const UnavailableFeaturePopover = ({ children, title, message }) => {
    const { t } = useTranslation();
    const resolvedMessage = message || t('POPOVER.MESSAGE');

    return (
        <OverlayTrigger
            trigger="click"
            placement="right"
            overlay={
                <Popover id="popover-unavailable-feature">
                    <Popover.Body>{resolvedMessage}</Popover.Body>
                </Popover>
            }
            rootClose>
            {children}
        </OverlayTrigger>
    );
};

UnavailableFeaturePopover.propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.string,
    message: PropTypes.string,
};


UnavailableFeaturePopover.defaultProps = {
    title: undefined,
    message: undefined,
  };

export default UnavailableFeaturePopover;