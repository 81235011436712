import { useEffect, useState } from "react";
import { Loader, TTNewContainer, TTNewCard, TTNewCardContent, AdvancedTable, Heading, TTNewRow, TTNewCol, TTNewButton, Icon, StatusTag } from 'taltech-styleguide';
import { makeGetRequest } from "../apiRequests";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAlert } from '../store/AlertContext';
import UnavailableFeaturePopover from '../components/UnavailableFeaturePopover';

const Dashboard = () => {
    const [curriculum, setCurriculum] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { showAlert } = useAlert();

    // the function that fetches data of all study programs
    useEffect(() => {
        const makeRequest = async () => {
            try {
                const studyProgramResponse = await makeGetRequest('/studyprogram');
                const studyPrograms = await studyProgramResponse.data;
                setCurriculum(studyPrograms);
                console.log(studyPrograms);
                setLoading(false);
            } catch (error) {
                showAlert(error.toString());
                setLoading(false);
            }
        };
        makeRequest();
    }, [showAlert]);

    const navigateToAddPage = () => {
        navigate('/add');
    };

    const viewDetail = (rowData) => {
        navigate('/studyplan/' + rowData.id);
    }

    const renderMoreButtonsOnHover = (rowData) => {
        return (
            <div className="d-flex justify-content-end">
                <Icon
                    name="more_vert"
                />
                <div className="more-actions-buttons">
                    <TTNewButton variant="link" onClick={() => viewDetail(rowData)}>
                        {t('ADVANCED_TABLE.VIEW')}
                    </TTNewButton>
                    <UnavailableFeaturePopover>
                        <TTNewButton variant="link">
                            {t('ADVANCED_TABLE.CHANGE')}
                        </TTNewButton>
                    </UnavailableFeaturePopover>
                    <UnavailableFeaturePopover>
                        <TTNewButton variant="link">
                            {t('ADVANCED_TABLE.SEND')}
                        </TTNewButton>
                    </UnavailableFeaturePopover>
                    <UnavailableFeaturePopover>
                        <TTNewButton variant="link">
                            {t('ADVANCED_TABLE.NEW_VERSION')}
                        </TTNewButton>
                    </UnavailableFeaturePopover>
                </div>
            </div>
        );
    }

    const renderStatusCell = () => {
        return (
            <StatusTag type="info" className="ps-3">
                {t(`DASHBOARD.ACTIVE`)}
            </StatusTag>
        )
    }

    return (
        <TTNewContainer>
            <TTNewRow>
                <TTNewCol size={{
                        xs: 12,
                        md: 6
                    }}>
                    <Heading className="mb-2 mb-md-5"
                        color="primary"
                        visual="display-2">
                        {t('DASHBOARD.TITLE')}
                    </Heading>
                </TTNewCol>
                <TTNewCol align="center" className="d-flex mb-3 mb-md-0" size={{
                        xs: 12,
                        md: 6
                    }}>
                    <TTNewButton iconLeft="add_circle" className="ms-auto" variant="primary" onClick={navigateToAddPage}>
                        {t('DASHBOARD.ADD_NEW_BUTTON')}
                    </TTNewButton>
                </TTNewCol>
            </TTNewRow>

            {loading ? (
                <Loader center fullScreen size="md" />
            ) : (
                <TTNewCard padding={1}>
                    <TTNewCardContent>
                        <AdvancedTable
                            columns={[
                                {
                                    dataField: 'id',
                                    text: t('DASHBOARD.COLUMNS.ID')
                                },
                                {
                                    dataField: 'code',
                                    text: t('DASHBOARD.COLUMNS.CODE')
                                },
                                {
                                    dataField: 'name_est',
                                    text: t('DASHBOARD.COLUMNS.NAME_EST')
                                },
                                {
                                    dataField: 'study_field_code',
                                    text: t('COURSES.COLUMNS.STUDY_FIELD'),
                                    formatter: (cellContent) => {
                                        return t(`STUDY_FIELDS.${cellContent}`);
                                    }
                                },
                                {
                                    dataField: 'study_volume',
                                    text: t('DASHBOARD.COLUMNS.STUDY_VOLUME')
                                },
                                {
                                    dataField: 'program_manager',
                                    text: t('DASHBOARD.COLUMNS.PROGRAM_MANAGER')
                                },
                                {
                                    dataField: 'status',
                                    text: t('DASHBOARD.COLUMNS.STATUS'),
                                    formatter: () => renderStatusCell()

                                },
                                {
                                    dataField: 'more',
                                    text: t('DASHBOARD.COLUMNS.ACTIVITIES'),
                                    formatter: (cell, row) => renderMoreButtonsOnHover(row)
                                }
                            ]}
                            data={curriculum}
                            keyField="id"
                            hideFilterBar
                            rowClasses="advanced-table-row"
                            onTableChange={function Na() { }}
                            pagination={{
                                sizePerPage: 10,
                                sizePerPageList: [
                                    {
                                        text: '10',
                                        value: 10
                                    },
                                    {
                                        text: '20',
                                        value: 20
                                    },
                                    {
                                        text: '50',
                                        value: 50
                                    },
                                    {
                                        text: '100',
                                        value: 100
                                    }
                                ]
                            }}
                        />
                    </TTNewCardContent>
                </TTNewCard>
            )}
        </TTNewContainer>
    )
};

export default Dashboard;