import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { Typography, TTNewButton, TTNewRow, TTNewCol, TTNewCard, TTNewCardContent, Heading, Tabs, TabPanel, ButtonGroup, AdvancedTable, Icon, Loader } from 'taltech-styleguide';
import { Container } from 'react-bootstrap';
import { makeGetRequest } from "../apiRequests";
import { useTranslation } from "react-i18next";
import { useAlert } from '../store/AlertContext';
import UnavailableFeaturePopover from '../components/UnavailableFeaturePopover';

const StudyPlanDetail = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [studyPlan, setStudyPlan] = useState([]);
    const [courses, setCourses] = useState([]);
    const [loading, setLoading] = useState(true);
    const { t, i18n } = useTranslation();
    const { showAlert } = useAlert();

    // useEffect hook to fetch data from the API and get the study program by ID from the URL, then uses ID to get the information about study program
    useEffect(() => {
        const studyProgramId = location.pathname.split('/').pop();
        const makeRequest = async () => {
            try {
                const studyProgramResponse = await makeGetRequest('/studyprogram/' + studyProgramId);
                const studyProgram = await studyProgramResponse.data;
                setStudyPlan(studyProgram);
                setCourses(studyProgram.courses);
                console.log(studyProgram);
                setLoading(false);
            } catch (error) {
                showAlert(error.toString());
                setLoading(false);
            }
        };
        makeRequest();
    }, [location.pathname, showAlert]);


    const returnToDashboard = () => {
        navigate('/dashboard');
    }

    const coursesWithIndexNumbers = courses.map((course, index) => ({
        ...course,
        number: index + 1,
    }));

    const renderTitleBasedOnLanguage = () => {
        if (i18n.language === 'et') {
            return (
                <Heading
                    color="primary"
                    visual="display-2">
                    {t('DETAIL_VIEW.STUDY_PLAN')}: {studyPlan.name_est}
                </Heading>
            );
        } else {
            return (
                <Heading
                    color="primary"
                    visual="display-2">
                    {t('DETAIL_VIEW.STUDY_PLAN')}: {studyPlan.name_eng}
                </Heading>
            );
        }
    };

    const viewDetail = (rowData) => {
        console.log('ID of the row:', rowData.id);
        navigate('/course/' + rowData.id);
    }

    const renderMoreButtonsOnHover = (rowData) => {
        return (
            <div className="d-flex justify-content-end">
                <Icon
                    name="more_vert"
                />
                <div className="more-actions-buttons">
                    <TTNewButton variant="link" onClick={() => viewDetail(rowData)}>
                        {t('ADVANCED_TABLE.VIEW_DETAILS')}
                    </TTNewButton>
                    <UnavailableFeaturePopover>
                        <TTNewButton variant="link">
                            {t('ADVANCED_TABLE.REMOVE')}
                        </TTNewButton>
                    </UnavailableFeaturePopover>
                </div>
            </div>
        );
    }

    const renderLangText = () => {
        if (studyPlan.language_est && studyPlan.language_eng) {
            return (
                <>
                    <Typography className="fw-bolder" visual="body">{t('ADD.COURSES.LANGUAGES.ET')}</Typography>
                    <Typography className="fw-bolder" visual="body">{t('ADD.COURSES.LANGUAGES.EN')}</Typography>
                </>
            );
        } else if (studyPlan.language_est) {
            return (
                <Typography className="fw-bolder" visual="body">{t('ADD.COURSES.LANGUAGES.ET')}</Typography>
            );
        } else if (studyPlan.language_eng) {
            return (
                <Typography className="fw-bolder" visual="body">{t('ADD.COURSES.LANGUAGES.EN')}</Typography>
            );
        }
    }

    return (
        <Container>
            <TTNewButton
                className="mb-2"
                iconLeft="arrow_back"
                color="body-color"
                variant="tertiary"
                onClick={returnToDashboard}>
                {t('GENERAL.BACK')}
            </TTNewButton>
            <TTNewRow alignItems="center" className="add-title">
                {renderTitleBasedOnLanguage()}
            </TTNewRow>
            {loading ? (
                <Loader center fullScreen size="md" />
            ) : (
                <TTNewRow>
                    <TTNewCol size={{
                        lg: 6,
                        sm: 12
                    }}>
                        <TTNewCard >
                            <TTNewCardContent>
                                <TTNewRow className="add-title">
                                    <TTNewCol><Heading as="h4">Õppekava üldine info</Heading></TTNewCol>
                                    <TTNewCol><Heading as="h4">eesti keeles</Heading></TTNewCol>
                                </TTNewRow>
                                <div className="mb-3">
                                    <Typography as="label" color="gray-700">Õppekava kood</Typography>
                                    <Typography className="fw-bolder" visual="body">{studyPlan.code}</Typography>
                                </div>
                                <div className="mb-3">
                                    <Typography as="label" color="gray-700">Õppekava nimetus</Typography>
                                    <Typography className="fw-bolder" visual="body">{studyPlan.name_est}</Typography>
                                </div>
                                <div className="mb-3">
                                    <Typography as="label" color="gray-700">Õppekava eesmärgid</Typography>
                                    <Typography className="fw-bolder" visual="body">{studyPlan.purpose_est}</Typography>
                                </div>
                                <div className="mb-3">
                                    <Typography as="label" color="gray-700">Õppetöö keeled</Typography>
                                    {renderLangText()}
                                </div>
                                <div className="mb-3">
                                    <Typography as="label" color="gray-700">Õppekava maht</Typography>
                                    <Typography className="fw-bolder" visual="body">{studyPlan.study_volume} EAP</Typography>
                                </div>
                                <div className="mb-3">
                                    <Typography as="label" color="gray-700">Õppekava kirjeldus</Typography>
                                    <Typography className="fw-bolder" visual="body">{studyPlan.description_est}</Typography>
                                </div>
                                <div className="mb-3">
                                    <Typography as="label" color="gray-700">Õppekava juht</Typography>
                                    <Typography className="fw-bolder" visual="body">{studyPlan.program_manager}</Typography>
                                </div>
                                <div className="mb-3">
                                    <Typography as="label" color="gray-700">Valdkond</Typography>
                                    <Typography className="fw-bolder" visual="body">{studyPlan.study_field.name_est}</Typography>
                                </div>
                            </TTNewCardContent>
                        </TTNewCard>

                    </TTNewCol>

                    <TTNewCol size={{
                        lg: 6,
                        xs: 12
                    }}>
                        <TTNewCard >
                            <TTNewCardContent>
                                <TTNewRow className="add-title">
                                    <TTNewCol size={8}><Heading as="h4">General information of the course</Heading></TTNewCol>
                                    <TTNewCol size={4}><Heading as="h4">in english</Heading></TTNewCol>
                                </TTNewRow>
                                <div className="mb-3">
                                    <Typography as="label" color="gray-700">Course code</Typography>
                                    <Typography className="fw-bolder" visual="body">{studyPlan.code}</Typography>
                                </div>
                                <div className="mb-3">
                                    <Typography as="label" color="gray-700">Course title</Typography>
                                    <Typography className="fw-bolder" visual="body">{studyPlan.name_eng}</Typography>
                                </div>
                                <div className="mb-3">
                                    <Typography as="label" color="gray-700">Learning outcomes</Typography>
                                    <Typography className="fw-bolder" visual="body">{studyPlan.purpose_eng}</Typography>
                                </div>
                                <div className="mb-3">
                                    <Typography as="label" color="gray-700">Course language</Typography>
                                    {renderLangText()}
                                </div>
                                <div className="mb-3">
                                    <Typography as="label" color="gray-700">Course volume</Typography>
                                    <Typography className="fw-bolder" visual="body">{studyPlan.study_volume} EAP</Typography>
                                </div>
                                <div className="mb-3">
                                    <Typography as="label" color="gray-700">Course description</Typography>
                                    <Typography className="fw-bolder" visual="body">{studyPlan.description_eng}</Typography>
                                </div>
                                <div className="mb-3">
                                    <Typography as="label" color="gray-700">Course leader</Typography>
                                    <Typography className="fw-bolder" visual="body">{studyPlan.program_manager}</Typography>
                                </div>
                                <div className="mb-3">
                                    <Typography as="label" color="gray-700">Field of study</Typography>
                                    <Typography className="fw-bolder" visual="body">{studyPlan.study_field.name_eng}</Typography>
                                </div>
                            </TTNewCardContent>
                        </TTNewCard>
                    </TTNewCol>

                    <TTNewCol size={12} className="mt-3">
                        <TTNewCard >
                            <TTNewCardContent>
                                <Heading as="h4">{t('ADD.COURSES.TITLE')}</Heading>
                                {courses ? (
                                    <Tabs
                                        id="course-languages"
                                        labels={[
                                            t('ADD.COURSES.LANGUAGES.ET'),
                                            t('ADD.COURSES.LANGUAGES.EN')
                                        ]}>
                                        <TabPanel>
                                            <AdvancedTable
                                                columns={[
                                                    {
                                                        dataField: 'number',
                                                        text: t('COURSES.COLUMNS.ID')
                                                    },
                                                    {
                                                        dataField: 'code',
                                                        text: 'Kood'
                                                    },
                                                    {
                                                        dataField: 'name_est',
                                                        text: 'Nimetus'
                                                    },
                                                    {
                                                        dataField: 'study_field_code',
                                                        text: 'Valdkond',
                                                        formatter: (cellContent) => {
                                                            return t(`STUDY_FIELDS.EST.${cellContent}`);
                                                        }
                                                    },
                                                    {
                                                        dataField: 'volume',
                                                        text: 'Maht'
                                                    },
                                                    {
                                                        dataField: 'semester',
                                                        text: 'Toimumise aeg',
                                                        formatter: (cellContent) => {
                                                            return t(`SEMESTER.EST.${cellContent}`);
                                                        }
                                                    },
                                                    {
                                                        dataField: 'language',
                                                        text: 'Keel',
                                                        formatter: (cellContent) => {
                                                            return t(`LANGUAGES.EST.${cellContent}`);
                                                        }
                                                    },
                                                    {
                                                        dataField: 'more',
                                                        text: 'Tegevus',
                                                        formatter: (cell, row) => renderMoreButtonsOnHover(row)
                                                    }

                                                ]}
                                                data={coursesWithIndexNumbers}
                                                keyField="id"
                                                rowClasses="advanced-table-row"
                                                hideFilterBar
                                                onTableChange={function Na() { }}
                                            />
                                        </TabPanel>
                                        <TabPanel>
                                            <AdvancedTable
                                                columns={[
                                                    {
                                                        dataField: 'number',
                                                        text: t('COURSES.COLUMNS.ID')
                                                    },
                                                    {
                                                        dataField: 'code',
                                                        text: 'Code'
                                                    },
                                                    {
                                                        dataField: 'name_eng',
                                                        text: 'Name'
                                                    },
                                                    {
                                                        dataField: 'study_field_code',
                                                        text: 'Field of study',
                                                        formatter: (cellContent) => {
                                                            return t(`STUDY_FIELDS.ENG.${cellContent}`);
                                                        }
                                                    },
                                                    {
                                                        dataField: 'volume',
                                                        text: 'Volume'
                                                    },
                                                    {
                                                        dataField: 'semester',
                                                        text: 'Semester',
                                                        formatter: (cellContent) => {
                                                            return t(`SEMESTER.ENG.${cellContent}`);
                                                        }
                                                    },
                                                    {
                                                        dataField: 'language',
                                                        text: 'Language',
                                                        formatter: (cellContent) => {
                                                            return t(`LANGUAGES.ENG.${cellContent}`);
                                                        }
                                                    },
                                                    {
                                                        dataField: 'more',
                                                        text: 'Activities',
                                                        formatter: (cell, row) => renderMoreButtonsOnHover(row)
                                                    }

                                                ]}
                                                data={coursesWithIndexNumbers}
                                                keyField="id"
                                                rowClasses="advanced-table-row"
                                                hideFilterBar
                                                onTableChange={function Na() { }}
                                            />
                                        </TabPanel>
                                    </Tabs>
                                ) : (
                                    <Typography className="my-5">{t('DETAIL_VIEW.NO_COURSES')}</Typography>
                                )}
                            </TTNewCardContent>
                        </TTNewCard>
                    </TTNewCol>
                </TTNewRow>
            )}
            <ButtonGroup className="my-5">
                <UnavailableFeaturePopover>
                    <TTNewButton variant="primary">
                        {t('ADD.ACTIONS.CHANGE')}
                    </TTNewButton>
                </UnavailableFeaturePopover>
                <UnavailableFeaturePopover>
                    <TTNewButton variant="primary">
                        {t('ADD.ACTIONS.SEND_TO_CONFIRMATION')}
                    </TTNewButton>
                </UnavailableFeaturePopover>
                <UnavailableFeaturePopover>
                    <TTNewButton className="btn-link" variant="outline" color="body-color">{t('ADD.ACTIONS.NEW_VERSION')}</TTNewButton>
                </UnavailableFeaturePopover>
            </ButtonGroup>
        </Container >
    );
};

export default StudyPlanDetail;