import config from "../index";
import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { TTNewButton, Input, Form, CustomInput, TTNewRow, TTNewCol, TTNewCard, TTNewCardContent, StatusTag, Heading, Tabs, TabPanel, ButtonGroup, AdvancedTable, FormFeedback, FilterBar, Separator, Icon, Loader, Typography } from 'taltech-styleguide';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import { Container } from 'react-bootstrap';
import { makePostRequest, makeGetRequest } from "../apiRequests";
import { useTranslation } from "react-i18next";
import { useAlert } from '../store/AlertContext';
import './Add.css';
import UnavailableFeaturePopover from "../components/UnavailableFeaturePopover";

const Add = () => {
    const navigate = useNavigate();
    const [selectCourseView, setSelectCourseView] = useState(false);
    const [selectedCourses, setSelectedCourses] = useState(false);
    const [loading, setLoading] = useState(false);
    const [courses, setCourses] = useState([]);
    const [studyFields, setStudyFields] = useState([]);
    const { t } = useTranslation();
    const [selectedStudyFieldCodes, setSelectedStudyFieldCodes] = useState([]);
    const { showAlert } = useAlert();

    // useeffect function that fetches courses from the database through GET request
    useEffect(() => {
        fetch(config.apiUrl + '/course', {
            method: 'GET'
        })
            .then(res => res.json())
            .then(obj => {
                setCourses(obj.data);
            })
            .catch(error => {
                showAlert(error.toString());
            });
    }, [showAlert]);

    // useeffect function that fetches study fields from the database through GET request, these will be used in select option and filterbars
    useEffect(() => {
        fetch(config.apiUrl + '/studyfield', {
            method: 'GET'
        })
            .then(res => res.json())
            .then(obj => {
                setStudyFields(obj.data);
            })
            .catch(error => {
                showAlert(error.toString());
            });
    }, [showAlert]);

    const [touched, setTouched] = useState({
        code: false,
        name_est: false,
        program_manager: false,
        study_field: false,
    });

    const [formData, setFormData] = useState({
        id: '',
        code: '',
        name_est: '',
        name_eng: '',
        purpose_est: '',
        purpose_eng: '',
        study_volume: '',
        status: '',
        language_est: false,
        language_eng: false,
        study_form: '',
        program_manager: '',
        certificate: '',
        description_est: '',
        description_eng: '',
        courses: [],
        study_field: {}
    });

    // function that handles changes in the form fields
    const handleChange = (e) => {
        const { name, type, value, checked } = e.target;

        if (name === "study_field") {
            const selectedStudyField = studyFields.find(field => field.code === value);
            setFormData({
                ...formData,
                [name]: selectedStudyField || {},
            });
        } else {
            setFormData({
                ...formData,
                [name]: type === 'checkbox' ? checked : value,
            });
        }
    };

    const handleBlur = (fieldName) => {
        setTouched(prev => ({ ...prev, [fieldName]: true }));
    };

    // function that handles form submission, checks if all required fields are filled and makes a POST request
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!formData.code || !formData.name_est || !formData.program_manager || !formData.study_field) {
            setTouched({
                code: true,
                name_est: true,
                program_manager: true,
                study_field: true,
            });
            return;
        }

        try {
            await makePostRequest('/studyprogram', formData);
            navigateToDetailView();
        } catch (error) {
            showAlert(error.toString());
        }
    };

    // function that navigates to the detail view of the created study program
    const navigateToDetailView = () => {
        setLoading(true);
        const createdProgram = formData.name_est;
        const makeRequest = async () => {
            try {
                const studyProgramsResponse = await makeGetRequest('/studyprogram');
                const studyPrograms = await studyProgramsResponse.data;
                const id = studyPrograms.find(program => program.name_est === createdProgram).id;
                setLoading(false);
                navigate('/studyplan/' + id);
            } catch (error) {
                showAlert(error.toString());;
                setLoading(false);
            }
        };
        makeRequest();
    }

    const navigateToCourseSelectView = () => {
        setSelectCourseView(true);
    }

    const returnToFormView = () => {
        setSelectCourseView(false);
    }

    const returnToDashboard = () => {
        navigate('/dashboard')
    }

    const viewDetail = (rowData) => {
        navigate('/course/' + rowData.id);
    };

    // function that saves the selected courses to the study program
    const saveCourses = () => {
        const totalVolume = calculateCourseVolume();
        setFormData(prevFormData => ({
            ...prevFormData,
            study_volume: totalVolume
        }));
        setSelectedCourses(true);
        returnToFormView();
    }

    const calculateCourseVolume = () => {
        return formData.courses.reduce((total, currentCourse) => total + currentCourse.volume, 0);
    }

    // function that handles changes in the filter bar
    const handleFilterChange = (selectedOptions) => {
        const selectedValues = selectedOptions.map(option => option.value);
        setSelectedStudyFieldCodes(selectedValues);
    };

    const colors = ['#A1C67D', '#AA1352', '#FFC75A', '#A84CD3', '#51BFD3', '#E87839', '#124BBA', '#9092AD'];
    let colorIndex = 0;
    const colorAssignments = () => {
        colorIndex = (colorIndex + 1) % colors.length;
        return colors[colorIndex];
    };

    const filteredCourses = courses.filter(course =>
        selectedStudyFieldCodes.length === 0 || selectedStudyFieldCodes.includes(course.study_field_code)
    );

    // function that adds indexing for course lists
    const coursesWithIndexNumbers = formData.courses.map((course, index) => ({
        ...course,
        number: index + 1,
    }));

    // remove selected course from the list of courses
    const removeCourse = (rowData) => {
        setFormData({
            ...formData,
            courses: formData.courses.filter((course) => course.id !== rowData.id)
        });
    };

    const renderMoreButtonsOnHover = (rowData) => {
        return (
            <div className="d-flex justify-content-end">
                <Icon
                    name="more_vert"
                />
                <div className="more-actions-buttons">
                    <TTNewButton variant="link" onClick={() => viewDetail(rowData)}>
                        {t('ADVANCED_TABLE.VIEW_DETAILS')}
                    </TTNewButton>
                    <TTNewButton variant="link" onClick={() => removeCourse(rowData)}>
                        {t('ADVANCED_TABLE.REMOVE')}
                    </TTNewButton>
                </div>
            </div>
        );
    }

    return (
        <Container>
            {loading && <Loader center fullScreen size="md" />}
            <Form>
                {!selectCourseView ? (
                    <>
                        <TTNewRow alignItems="center" className="add-title">
                            <Heading
                                color="primary"
                                visual="display-2">
                                {t('ADD.TITLE')}
                            </Heading>
                            <StatusTag type="info" className="add-status">
                                {t('ADD.STATUS.SISESTAMISEL')}
                            </StatusTag>
                        </TTNewRow>
                        <TTNewRow>
                            <TTNewCol size={{
                                lg: 6,
                                sm: 12
                            }}>
                                <TTNewCard >
                                    <TTNewCardContent>
                                        <TTNewRow className="add-title">
                                            <TTNewCol><Heading as="h4">Õppekava üldine info</Heading></TTNewCol>
                                            <TTNewCol><Heading as="h4">eesti keeles</Heading></TTNewCol>
                                        </TTNewRow>
                                        <FormGroup className="mb-3">
                                            <FormLabel htmlFor="code">Õppekava kood *</FormLabel>
                                            <Input
                                                placeholder="sisesta õppekava kood"
                                                id="code"
                                                name="code"
                                                value={formData.code}
                                                onChange={handleChange}
                                                onBlur={() => handleBlur('code')}
                                                isInvalid={touched.code && !formData.code}
                                            />
                                            {touched.code && !formData.code && <FormFeedback type="danger">
                                                {t('ADD.FORM_VALIDATION.BASIC')}</FormFeedback>}
                                        </FormGroup>
                                        <FormGroup className="mb-3">
                                            <FormLabel htmlFor="name_est">Õppekava nimetus *</FormLabel>
                                            <Input
                                                placeholder="sisesta õppekava nimetus"
                                                id="name_est"
                                                name="name_est"
                                                value={formData.name_est}
                                                onChange={handleChange}
                                                onBlur={() => handleBlur('name_est')}
                                                isInvalid={touched.name_est && !formData.name_est}
                                            />
                                            {touched.name_est && !formData.name_est && <FormFeedback type="danger">
                                                {t('ADD.FORM_VALIDATION.BASIC')}</FormFeedback>}
                                        </FormGroup>
                                        <FormGroup className="mb-3">
                                            <FormLabel htmlFor="purpose_est">Õppekava eesmärgid</FormLabel>
                                            <Input
                                                placeholder="sisesta eesmärgid"
                                                id="purpose_est"
                                                name="purpose_est"
                                                value={formData.purpose_est}
                                                onChange={handleChange}
                                            />
                                        </FormGroup>
                                        <FormGroup className="mb-3">
                                            <TTNewRow>
                                                <FormLabel>Õppetöö keeled</FormLabel>
                                            </TTNewRow>
                                            <>
                                                <CustomInput
                                                    id="language_est_eesti"
                                                    name="language_est"
                                                    inline
                                                    label="eesti keel"
                                                    type="checkbox"
                                                    checked={formData.language_est}
                                                    onChange={handleChange}
                                                />
                                                <CustomInput
                                                    id="language_eng_eesti"
                                                    name="language_eng"
                                                    inline
                                                    label="inglise keel"
                                                    type="checkbox"
                                                    checked={formData.language_eng}
                                                    onChange={handleChange}
                                                />
                                            </>
                                        </FormGroup>
                                        <FormGroup className="mb-3">
                                            <FormLabel htmlFor="study_volume">Õppekava maht (arvutatav kursuste lisamisel)</FormLabel>
                                            <Input
                                                disabled
                                                placeholder="0 EAP"
                                                id="study_volume"
                                                name="study_volume"
                                                value={formData.study_volume || 0}
                                                onChange={handleChange}
                                                readOnly
                                            />
                                        </FormGroup>
                                        <FormGroup className="mb-3">
                                            <FormLabel htmlFor="description_est">Õppekava kirjeldus</FormLabel>
                                            <Input
                                                placeholder="sisesta õppetöö kirjeldus"
                                                id="description_est"
                                                name="description_est"
                                                value={formData.description_est}
                                                onChange={handleChange}
                                            />
                                        </FormGroup>
                                        <FormGroup className="mb-3">
                                            <FormLabel htmlFor="program_manager">Õppekava juht *</FormLabel>
                                            <Input
                                                placeholder="Programmijuhi nimi"
                                                id="program_manager"
                                                name="program_manager"
                                                value={formData.program_manager}
                                                onChange={handleChange}
                                                onBlur={() => handleBlur('program_manager')}
                                                isInvalid={touched.program_manager && !formData.program_manager}
                                            />
                                            {touched.program_manager && !formData.program_manager && <FormFeedback type="danger">
                                                {t('ADD.FORM_VALIDATION.PROGRAM_MANAGER')}</FormFeedback>}
                                        </FormGroup>
                                        <FormGroup className="mb-3">
                                            <FormLabel htmlFor="study_field">Valdkond *</FormLabel>
                                            <Input
                                                as="select"
                                                name="study_field"
                                                id="study_field"
                                                value={formData.study_field.code || ''}
                                                onChange={handleChange}
                                                onBlur={() => handleBlur('study_field')}
                                                isInvalid={touched.study_field && !formData.study_field.code}
                                            >
                                                <option value="">Vali õppekava valdkond</option>
                                                {studyFields.map((field) => (
                                                    <option key={field.code} value={field.code}>{field.name_est}</option>
                                                ))}
                                            </Input>
                                            {touched.study_field && !formData.study_field.code && <FormFeedback type="danger">
                                                {t('ADD.FORM_VALIDATION.STUDY_FIELD')}</FormFeedback>}
                                        </FormGroup>
                                    </TTNewCardContent>
                                </TTNewCard>

                            </TTNewCol>

                            <TTNewCol size={{
                                lg: 6,
                                xs: 12
                            }}>
                                <TTNewCard >
                                    <TTNewCardContent>
                                        <TTNewRow className="add-title">
                                            <TTNewCol size={8}><Heading as="h4">General information of the course</Heading></TTNewCol>
                                            <TTNewCol size={4}><Heading as="h4">in english</Heading></TTNewCol>
                                        </TTNewRow>
                                        <FormGroup className="mb-3">
                                            <FormLabel htmlFor="code">Course code</FormLabel>
                                            <Input
                                                id="code"
                                                name="code"
                                                disabled
                                                value={formData.code}
                                            />
                                        </FormGroup>
                                        <FormGroup className="mb-3">
                                            <FormLabel htmlFor="name_eng">Course title</FormLabel>
                                            <Input
                                                placeholder="add course title"
                                                id="name_eng"
                                                name="name_eng"
                                                value={formData.name_eng}
                                                onChange={handleChange} />
                                        </FormGroup>
                                        <FormGroup className="mb-3">
                                            <FormLabel htmlFor="purpose_eng">Learning outcomes</FormLabel>
                                            <Input
                                                placeholder="add outcomes"
                                                id="purpose_eng"
                                                name="purpose_eng"
                                                value={formData.purpose_eng}
                                                onChange={handleChange}
                                            />
                                        </FormGroup>
                                        <FormGroup className="mb-3">
                                            <TTNewRow>
                                                <FormLabel>Course language</FormLabel>
                                            </TTNewRow>
                                            <>
                                                <CustomInput
                                                    id="language_est_english"
                                                    name="language_est"
                                                    inline
                                                    label="eesti keel"
                                                    type="checkbox"
                                                    checked={formData.language_est}
                                                    onChange={handleChange}
                                                />
                                                <CustomInput
                                                    id="language_eng_english"
                                                    name="language_eng"
                                                    inline
                                                    label="inglise keel"
                                                    type="checkbox"
                                                    checked={formData.language_eng}
                                                    onChange={handleChange}
                                                />
                                            </>
                                        </FormGroup>
                                        <FormGroup className="mb-3">
                                            <FormLabel htmlFor="study_volume">Course volume (...)</FormLabel>
                                            <Input
                                                disabled
                                                id="study_volume"
                                                name="study_volume"
                                                value={formData.study_volume || 0}
                                            />
                                        </FormGroup>
                                        <FormGroup className="mb-3">
                                            <FormLabel htmlFor="description_eng">Course description</FormLabel>
                                            <Input
                                                placeholder="add course description"
                                                id="description_eng"
                                                name="description_eng"
                                                value={formData.description_eng}
                                                onChange={handleChange}
                                            />
                                        </FormGroup>
                                        <FormGroup className="mb-3">
                                            <FormLabel htmlFor="program_manager">Course leader</FormLabel>
                                            <Input
                                                disabled
                                                id="program_manager"
                                                name="program_manager"
                                                value={formData.program_manager}
                                            />
                                        </FormGroup>
                                        <FormGroup className="mb-3">
                                            <FormLabel htmlFor="study_field">Field of study</FormLabel>
                                            <Input
                                                disabled
                                                id="study_field"
                                                name="study_field"
                                                value={formData.study_field.name_eng || ''}
                                            />
                                        </FormGroup>
                                    </TTNewCardContent>
                                </TTNewCard>
                            </TTNewCol>

                            <TTNewCol size={12} className="mt-3">
                                <TTNewCard >
                                    <TTNewCardContent>
                                        <Heading as="h4">{t('ADD.COURSES.TITLE')}</Heading>
                                        {selectedCourses ? (
                                            <Tabs
                                                id="course-languages"
                                                labels={[
                                                    t('ADD.COURSES.LANGUAGES.ET'),
                                                    t('ADD.COURSES.LANGUAGES.EN')
                                                ]}>
                                                <TabPanel>
                                                    <AdvancedTable
                                                        columns={[
                                                            {
                                                                dataField: 'number',
                                                                text: t('COURSES.COLUMNS.ID')
                                                            },
                                                            {
                                                                dataField: 'code',
                                                                text: 'Kood'
                                                            },
                                                            {
                                                                dataField: 'name_est',
                                                                text: 'Nimetus'
                                                            },
                                                            {
                                                                dataField: 'study_field_code',
                                                                text: 'Valdkond',
                                                                formatter: (cellContent) => {
                                                                    return t(`STUDY_FIELDS.EST.${cellContent}`);
                                                                }
                                                            },
                                                            {
                                                                dataField: 'volume',
                                                                text: 'Maht'
                                                            },
                                                            {
                                                                dataField: 'semester',
                                                                text: 'Toimumise aeg',
                                                                formatter: (cellContent) => {
                                                                    return t(`SEMESTER.EST.${cellContent}`);
                                                                }
                                                            },
                                                            {
                                                                dataField: 'language',
                                                                text: 'Keel',
                                                                formatter: (cellContent) => {
                                                                    return t(`LANGUAGES.EST.${cellContent}`);
                                                                }
                                                            },
                                                            {
                                                                dataField: 'more',
                                                                text: 'Tegevus',
                                                                formatter: (cell, row) => renderMoreButtonsOnHover(row)
                                                            }
                                                        ]}
                                                        data={coursesWithIndexNumbers}
                                                        keyField="id"
                                                        rowClasses="advanced-table-row"
                                                        hideFilterBar
                                                        onTableChange={function Na() { }}
                                                    />
                                                </TabPanel>

                                                <TabPanel>
                                                    <AdvancedTable
                                                        columns={[
                                                            {
                                                                dataField: 'number',
                                                                text: t('COURSES.COLUMNS.ID')
                                                            },
                                                            {
                                                                dataField: 'code',
                                                                text: 'Code'
                                                            },
                                                            {
                                                                dataField: 'name_eng',
                                                                text: 'Name'
                                                            },
                                                            {
                                                                dataField: 'study_field_code',
                                                                text: 'Field of study',
                                                                formatter: (cellContent) => {
                                                                    return t(`STUDY_FIELDS.ENG.${cellContent}`);
                                                                }
                                                            },
                                                            {
                                                                dataField: 'volume',
                                                                text: 'Volume'
                                                            },
                                                            {
                                                                dataField: 'semester',
                                                                text: 'Semester',
                                                                formatter: (cellContent) => {
                                                                    return t(`SEMESTER.ENG.${cellContent}`);
                                                                }
                                                            },
                                                            {
                                                                dataField: 'language',
                                                                text: 'Language',
                                                                formatter: (cellContent) => {
                                                                    return t(`LANGUAGES.ENG.${cellContent}`);
                                                                }
                                                            },
                                                            {
                                                                dataField: 'more',
                                                                text: 'Activities',
                                                                formatter: (cell, row) => renderMoreButtonsOnHover(row)
                                                            }

                                                        ]}
                                                        data={coursesWithIndexNumbers}
                                                        keyField="id"
                                                        rowClasses="advanced-table-row"
                                                        hideFilterBar
                                                        onTableChange={function Na() { }}
                                                    />
                                                </TabPanel>
                                            </Tabs>
                                        ) : (
                                            <Typography className="my-5">{t('ADD.COURSES.NO_COURSES')}</Typography>
                                        )}
                                        <TTNewButton iconLeft="add_circle" className="mt-5" variant="primary" onClick={navigateToCourseSelectView}>
                                            {t('ADD.FORM.ADD_COURSES')}
                                        </TTNewButton>
                                    </TTNewCardContent>
                                </TTNewCard>
                            </TTNewCol>
                        </TTNewRow>
                        <ButtonGroup className="mb-5">
                            <TTNewButton variant="primary" onClick={handleSubmit}>
                                {t('ADD.ACTIONS.SAVE')}
                            </TTNewButton>
                            <UnavailableFeaturePopover>
                                <TTNewButton variant="primary">{t('ADD.ACTIONS.SEND_TO_CONFIRMATION')}</TTNewButton>
                            </UnavailableFeaturePopover>
                            <UnavailableFeaturePopover>
                                <TTNewButton className="btn-link" variant="outline" color="body-color">{t('ADD.ACTIONS.NEW_VERSION')}</TTNewButton>
                            </UnavailableFeaturePopover>
                            <TTNewButton className="btn-link" variant="outline" color="body-color" onClick={returnToDashboard}>{t('ADD.ACTIONS.CANCEL')}</TTNewButton>
                        </ButtonGroup>
                    </>
                ) : (
                    <>
                        <TTNewButton
                            className="mb-2"
                            iconLeft="arrow_back"
                            color="body-color"
                            variant="tertiary"
                            onClick={returnToFormView}>
                            {t('GENERAL.BACK')}
                        </TTNewButton>
                        <Heading
                            className="mb-5"
                            color="primary"
                            visual="display-2">
                            {t('ADD.FORM.COURSE_TITLE_1')} <span className="fst-italic fw-normal">{formData.name_est ? formData.name_est : t('ADD.FORM.COURSE_NAME')}</span> {t('ADD.FORM.COURSE_TITLE_2')}
                        </Heading>
                        <TTNewCard padding={{
                            xs: 0.8,
                            md: 2.5
                        }}>
                            <TTNewCardContent>
                                <FilterBar
                                    allToggle="Kõik"
                                    className="rounded-pill"
                                    onChange={handleFilterChange}
                                    value={selectedStudyFieldCodes}
                                    options={studyFields.map(field => ({
                                        value: field.code,
                                        label: field.name_est,
                                        color: colorAssignments()
                                    }))}
                                >
                                </FilterBar>
                                <Separator />
                                <AdvancedTable
                                    columns={[
                                        {
                                            dataField: 'id',
                                            text: t('COURSES.COLUMNS.ID')
                                        },
                                        {
                                            dataField: 'code',
                                            text: t('COURSES.COLUMNS.CODE')
                                        },
                                        {
                                            dataField: 'name_est',
                                            text: t('COURSES.COLUMNS.NAME_EST')
                                        },
                                        {
                                            dataField: 'study_field_code',
                                            text: t('COURSES.COLUMNS.STUDY_FIELD'),
                                            formatter: (cellContent) => {
                                                return t(`STUDY_FIELDS.${cellContent}`);
                                            }
                                        },
                                        {
                                            dataField: 'volume',
                                            text: t('COURSES.COLUMNS.VOLUME')
                                        },
                                        {
                                            dataField: 'semester',
                                            text: t('COURSES.COLUMNS.SEMESTER'),
                                            formatter: (cellContent) => {
                                                return t(`SEMESTER.${cellContent}`);
                                            }
                                        },
                                        {
                                            dataField: 'language',
                                            text: t('COURSES.COLUMNS.LANGUAGE'),
                                            formatter: (cellContent) => {
                                                return t(`LANGUAGES.${cellContent}`);
                                            }
                                        },
                                        {
                                            dataField: 'more',
                                            text: t('DASHBOARD.COLUMNS.ACTIVITIES'),
                                            formatter: (cell, row) => renderMoreButtonsOnHover(row)
                                        }

                                    ]}
                                    selectRow={{
                                        mode: 'checkbox',
                                        clickToSelect: true,
                                        selected: formData.courses.map((course) => course.id),
                                        onSelect: (row, isSelect) => {
                                            if (isSelect) {
                                                setFormData({
                                                    ...formData,
                                                    courses: [...formData.courses, row]
                                                });
                                            } else {
                                                setFormData({
                                                    ...formData,
                                                    courses: formData.courses.filter((course) => course.id !== row.id)
                                                });
                                            }
                                        }
                                    }}
                                    data={filteredCourses}
                                    keyField="id"
                                    rowClasses="advanced-table-row"
                                    hideFilterBar
                                    onTableChange={function Na() { }}
                                />
                            </TTNewCardContent>
                        </TTNewCard>
                        <ButtonGroup className="mt-5">
                            <TTNewButton variant="primary" onClick={saveCourses}>
                                {t('ADD.FORM.ADD_COURSES')}
                            </TTNewButton>
                            <TTNewButton className="btn-link" variant="outline" color="body-color" onClick={returnToFormView}>{t('GENERAL.CANCEL')}</TTNewButton>
                        </ButtonGroup>
                    </>
                )}

            </Form>
        </Container >
    );
};

export default Add;