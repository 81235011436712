import { React } from 'react';
import { Footer } from 'taltech-styleguide';
import LinkWrapper from './LinkWrapper';

const FooterComponent = () => {
  const footerLinks = [
    {
      label: 'Versioon 1.0',
      links: [
        {
          children: 'Datel AS proovitöö prototüüp Figmas',
          target: '_blank',
          to: 'https://www.figma.com/proto/TraogIb7pDjkvQ2d5moI9d/Proovit%C3%B6%C3%B6?page-id=1%3A2&type=design&node-id=6-43&viewport=82%2C155%2C0.25&t=xilUlOlYUmTG4DrD-1&scaling=min-zoom'
        }
      ]
    }
  ];

  return (
    <Footer className="footer-mt" linkAs={LinkWrapper} linkGroups={footerLinks} />
  );
};

export default FooterComponent;