import React, { createContext, useContext, useState, useMemo, useCallback } from 'react';
import CustomAlert from '../components/Alert';
import PropTypes from 'prop-types';

// context API for alerts
const AlertContext = createContext();

export const useAlert = () => useContext(AlertContext);

export const AlertProvider = ({ children }) => {
    const [alert, setAlert] = useState({ show: false, message: '', variant: 'warning' });

    const showAlert = useCallback((message, variant = 'warning') => {
        setAlert({ show: true, message, variant });
    }, []); 

    const hideAlert = useCallback(() => {
        setAlert((currentAlert) => ({ ...currentAlert, show: false }));
    }, []);

    const contextValue = useMemo(() => ({ showAlert, hideAlert }), [showAlert, hideAlert]);

    return (
        <AlertContext.Provider value={contextValue}>
            {alert.show && <CustomAlert message={alert.message} variant={alert.variant} onClose={hideAlert} />}
            {children}
        </AlertContext.Provider>
    );
};

AlertProvider.propTypes = {
    children: PropTypes.node.isRequired,
};
