import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Input, TTNewButton, TTNewContainer, Form, TTNewRow, TTNewCol, Heading, Tabs, TabPanel, ButtonGroup, FormFeedback, Loader, Typography } from 'taltech-styleguide';
import FormGroup from 'react-bootstrap/FormGroup';
import FormLabel from 'react-bootstrap/FormLabel';
import Image from 'react-bootstrap/Image';
import { makePostRequest } from "../apiRequests";
import { useUser } from "../store/UserContext";
import taltechImage from '../assets/img/taltech.png';
import taltechLogo from '../assets/img/taltechlogo.svg';
import './Login.css';
import { useTranslation } from "react-i18next";
import { useAlert } from '../store/AlertContext';


const Login = () => {
    const { loginUser, returnToMain, setLoginPage } = useUser();
    const location = useLocation();
    const [email, setEmail] = useState("");
    const [verificationCode, setVerificationCode] = useState("");
    const [step, setStep] = useState(1);
    const [emailFilled, setEmailFilled] = useState(false);
    const [emailValid, setEmailValid] = useState(false);
    const [verificationFilled, setVerificationFilled] = useState(false);
    const [verificationCodeValid, setVerificationCodeValid] = useState(false);
    const [emailTouched, setEmailTouched] = useState(false);
    const [verificationCodeTouched, setVerificationCodeTouched] = useState(false);
    const { t } = useTranslation();
    const { showAlert } = useAlert();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (location.pathname === "/Login") {
            location.pathname = "/login";
            setLoginPage(true);
        }
    }, [location, setLoginPage]);

    // function that handles the change of the email input and validates it
    const handleEmailChange = (e) => {
        const { value } = e.target;
        setEmail(value);
        setEmailFilled(value.trim() !== "");
        setEmailValid(validateEmail(value));
    };

    // function that handles the change of the verification input and validates it
    const handleVerificationChange = (e) => {
        const trimmedValue = e.target.value.trim();
        setVerificationCode(trimmedValue);
        setVerificationFilled(trimmedValue !== "");
        const isValid = validateVerificationCode(trimmedValue);
        setVerificationCodeValid(isValid);
    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const validateVerificationCode = (code) => {
        const verificationCodeRegex = /^\d{6}$/;
        return verificationCodeRegex.test(code);
    };

    const renderImage = () => {
        return <Image src={taltechImage} alt="Taltech hoone" fluid />;
    };

    const renderLogo = () => {
        return <Image src={taltechLogo} alt="Taltech logo" className="logo" />;
    };

    // function for sending the verification code again
    const resendCode = async () => {
        try {
            await makePostRequest('/otp/request', { email });
        } catch (error) {
            showAlert(error.message);
        }
    }

    const handleLogin = async (e) => {
        e.preventDefault();
        setLoading(true);
        if (step === 1) {
            emailValidationProcess();
        } else if (step === 2) {
            verificationCodeValidationProcess();
        }
    };

    // email validation process function for step 1 through POST request, return errors if it fails
    const emailValidationProcess = async (e) => {
        if (emailFilled && validateEmail(email)) {

            try {
                await makePostRequest('/otp/request', { email });
                setLoading(false);
                setStep(2);
            } catch (error) {
                showAlert(error.toString());
                setLoading(false);
            }

        } else {
            showAlert(t('LOGIN_PAGE.ERRORS.EMAIL'));
            setLoading(false);
        }
    }

    // verification code validation process function for step 2 through POST request, return errors if it fails
    const verificationCodeValidationProcess = async (e) => {
        setLoading(true);
        if (verificationFilled && validateVerificationCode(verificationCode)) {

            try {
                await makePostRequest('/otp/submit', { otp: verificationCode });
                setLoading(false);
                loginUser();
            } catch (error) {
                showAlert(t('LOGIN_PAGE.ERRORS.CORRECT_CODE'));
                setLoading(false);
            }

        } else {
            showAlert(t('LOGIN_PAGE.ERRORS.CORRECT_CODE'));
            setLoading(false);
        }
    }

    // function that handles the blur of the input fields
    const handleBlur = (field) => {
        if (field === 'email') {
            setEmailTouched(true);
        } else if (field === 'verificationCode') {
            setVerificationCodeTouched(true);
        }
    };

    return (
        <div className="background">
            {loading && <Loader center fullScreen size="md" />}
            <TTNewContainer className="background-container">
                <TTNewRow className="logo-padding">
                    <TTNewCol size={{
                        xs: 12,
                        lg: 4
                    }} className="px-0 column-welcome">
                        <TTNewRow className="w-100 ">
                            <TTNewCol size={2} className="px-0 h-100 position-relative">
                                <div className="column-welcome-sub1 position-absolute w-100 bottom-0"></div>
                            </TTNewCol>
                            <TTNewCol size={2} className="px-0 h-100 position-relative">
                                <div className="column-welcome-sub2 position-absolute w-100 bottom-0"></div>
                            </TTNewCol>
                            <TTNewCol size={8} className="column-welcome-sub3 d-flex justify-content-start justify-content-md-end pt-5 pe-2">
                                <Heading className="mt-5" color="primary" visual="display-2">{t('LOGIN_PAGE.TITLE')}</Heading>
                            </TTNewCol>
                        </TTNewRow>
                    </TTNewCol>
                    <TTNewCol size={{
                        xs: 12,
                        lg: 5
                    }} className="column-login px-4">
                        <Tabs
                            id="login-tabs"
                            labels={[
                                t('LOGIN_PAGE.OTP'),
                                t('LOGIN_PAGE.SMART_ID'),
                                t('LOGIN_PAGE.MOBILE_ID'),
                                t('LOGIN_PAGE.ID_CARD'),
                            ]}>
                            <TabPanel>
                                {step === 1 ? (
                                    <>
                                        <Typography className="my-5">{t('LOGIN_PAGE.OTP_DESCRIPTION')}</Typography>
                                        <Form>
                                            <FormGroup className="login-formGroup">
                                                <FormLabel htmlFor="email">{t('LOGIN_PAGE.EMAIL')}</FormLabel>
                                                <Input
                                                    id="email"
                                                    name="email"
                                                    placeholder={t('LOGIN_PAGE.EMAIL_PLACEHOLDER')}
                                                    value={email}
                                                    onChange={handleEmailChange}
                                                    onBlur={() => handleBlur('email')}
                                                    isInvalid={emailTouched && !emailValid && !emailFilled}
                                                />
                                                {emailTouched && !emailValid && <FormFeedback type="danger">{t('LOGIN_PAGE.ERRORS.EMAIL')}</FormFeedback>}
                                                {emailTouched && !emailFilled && <FormFeedback type="danger">{t('LOGIN_PAGE.ERRORS.BASIC')}</FormFeedback>}
                                            </FormGroup>

                                            <ButtonGroup className="mt-5">
                                                <TTNewButton variant="primary" disabled={!emailFilled} onClick={handleLogin}>
                                                    {t('LOGIN_PAGE.SEND_CODE')}
                                                </TTNewButton>
                                                <TTNewButton className="btn-link" variant="outline" color="body-color" onClick={returnToMain}>{t('GENERAL.CANCEL')}</TTNewButton>
                                            </ButtonGroup>
                                        </Form>
                                    </>
                                ) : (
                                    <>
                                        <Typography className="my-5">{t('LOGIN_PAGE.CODE_SENT', { email: email })}</Typography>
                                        <Form>
                                            <FormGroup className="login-formGroup">
                                                <FormLabel htmlFor="verificationCode">{t('LOGIN_PAGE.VERIFICATION_CODE')}</FormLabel>
                                                <Input
                                                    id="verificationCode"
                                                    name="verificationCode"
                                                    type="text"
                                                    placeholder={t('LOGIN_PAGE.VERIFICATION_CODE_PLACEHOLDER')}
                                                    value={verificationCode}
                                                    onChange={handleVerificationChange}
                                                    onBlur={() => handleBlur('verificationCode')}
                                                    isInvalid={verificationCodeTouched && !verificationCodeValid}
                                                />
                                                {verificationCodeTouched && !verificationCodeValid && (
                                                    <FormFeedback type="danger">{t('LOGIN_PAGE.ERRORS.CODE')}</FormFeedback>
                                                )}
                                            </FormGroup>
                                            <ButtonGroup className="mt-5">
                                                <TTNewButton variant="primary" disabled={!verificationFilled} onClick={handleLogin}>
                                                    {t('LOGIN_PAGE.TITLE')}
                                                </TTNewButton>
                                                <TTNewButton className="btn-link" variant="outline" color="body-color" onClick={resendCode}>{t('LOGIN_PAGE.SEND_AGAIN')}</TTNewButton>
                                                <TTNewButton className="btn-link" variant="outline" color="body-color" onClick={returnToMain}>{t('GENERAL.CANCEL')}</TTNewButton>
                                            </ButtonGroup>
                                        </Form>
                                    </>
                                )}
                            </TabPanel>
                            <TabPanel>
                                <Typography className="my-5">{t('LOGIN_PAGE.SMART_ID_DESCRIPTION')}</Typography>
                            </TabPanel>
                            <TabPanel><Typography className="my-5">{t('LOGIN_PAGE.MOBILE_ID_DESCRIPTION')}</Typography></TabPanel>
                            <TabPanel><Typography className="my-5">{t('LOGIN_PAGE.ID_CARD_DESCRIPTION')}</Typography></TabPanel>
                        </Tabs>
                    </TTNewCol>

                    <TTNewCol size={{
                        xs: 12,
                        lg: 3
                    }} className="position-relative px-0">
                        {renderLogo()}
                        {renderImage()}
                    </TTNewCol>
                </TTNewRow>
            </TTNewContainer>
        </div>
    );
};

export default Login;