import { TTNewRow, TTNewCol, TTNewCard, TTNewContainer, TTNewCardContent, TTNewButton, Heading } from 'taltech-styleguide';
import { Container } from 'react-bootstrap';
import { useUser } from "../store/UserContext";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";

const Main = () => {
    const { toLoginPage, session } = useUser();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleLoginClick = () => {
        if (session.user) {
            navigate('/dashboard');
        } else {
            toLoginPage();
        }
    };


    return (
        <Container className="mb-3">
            <TTNewContainer>
                <TTNewRow>
                    <TTNewCol size={{
                        xs: 12,
                        md: 7
                    }}>
                        <TTNewCard >
                            <TTNewCardContent className="main-cardContent">
                                <Heading
                                    className="mb-2 mb-md-5"
                                    color="primary"
                                    visual="display-2">
                                    {t('HOME_PAGE.WELCOME')}
                                </Heading>
                                <Heading as="h4" className="pt-5">{t('HOME_PAGE.INTRODUCTION')}</Heading>
                                <Heading as="h4">{t('HOME_PAGE.LIST_TITLE')}</Heading>
                                <ul className="list-styled">
                                    <li className="default">{t('HOME_PAGE.LIST_1')}</li>
                                    <li className="default">{t('HOME_PAGE.LIST_2')}</li>
                                    <li className="default">{t('HOME_PAGE.LIST_3')}</li>
                                    <li className="default">{t('HOME_PAGE.LIST_4')}</li>
                                    <li className="default">{t('HOME_PAGE.LIST_5')}</li>
                                    <li className="default">{t('HOME_PAGE.LIST_6')}</li>
                                    <li className="default">{t('HOME_PAGE.LIST_7')}</li>
                                </ul>
                            </TTNewCardContent>
                        </TTNewCard>
                    </TTNewCol>
                    <TTNewCol size={{
                        xs: 12,
                        md: 5
                    }}>
                        <TTNewCard className="h-100">
                            <TTNewCardContent className="main-cardContent">
                                <Heading
                                    className="mb-2 mb-md-5"
                                    color="primary"
                                    visual="display-2">
                                    {t('HOME_PAGE.LOG_IN')}
                                </Heading>
                                <Heading as="h4" className="py-5">{t('HOME_PAGE.LOG_IN_INFO')}</Heading>
                                <TTNewButton onClick={handleLoginClick} className="d-block mt-5 mx-auto">
                                    {session.user ? t('HOME_PAGE.GO_TO_DASHBOARD') : t('HOME_PAGE.LOG_IN')}
                                </TTNewButton>
                            </TTNewCardContent>
                        </TTNewCard>
                    </TTNewCol>
                </TTNewRow>
            </TTNewContainer>
        </Container>
    );
}

export default Main;