import config from "./index";

export async function makeGetRequest(path){

    const res = await fetch(config.apiUrl + path, {
        method: 'GET'
    });

    const text = await res.text();

    if (!res.ok) {
        let errorMessage = "Bad response from server";
        try {
            const errorObj = JSON.parse(text);
            errorMessage = errorObj.message || errorMessage;
        } catch (e) {
        }
        throw new Error(errorMessage);
    }

    if(!text){
        return undefined;
    }

    return JSON.parse(text);

}

export async function makePostRequest(path, body){

    const res = await fetch(config.apiUrl + path, {
        method: 'POST',
        headers: {
            'Content-type': 'application/json; charset=UTF-8'
        },
        body: JSON.stringify(body)
    });
    
    const text = await res.text();

    if (!res.ok) {
        let errorMessage = "Bad response from server";
        try {
            const errorObj = JSON.parse(text);
            errorMessage = errorObj.message || errorMessage;
        } catch (e) {
        }
        throw new Error(errorMessage);
    }


    if(!text){
        return undefined;
    }

    return JSON.parse(text);

}

