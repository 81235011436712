import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import ReactDOM from 'react-dom/client';
import { UserProvider, useUser } from './store/UserContext';
import 'taltech-styleguide/index.css';
import './index.css';
import { ConfigProvider } from 'taltech-styleguide';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Add from './pages/Add';
import Courses from './pages/Courses';
import Main from './pages/Main';
import HeaderComponent from "./components/Header";
import { I18nextProvider, useTranslation } from "react-i18next";
import i18next from "i18next";
import Backend from 'i18next-http-backend';
import FooterComponent from './components/Footer';
import StudyPlanDetail from './pages/StudyPlanDetail';
import CourseDetail from './pages/CourseDetail';
import { AlertProvider } from './store/AlertContext';
import PropTypes from 'prop-types';

const config = (() => {
    if (process.env.NODE_ENV === 'development') {
        return require('./config/config.development').config;
    } else {
        return require('./config/config.prod').config;
    }
})();
export default config;

i18next
    .use(Backend)
    .init({
        fallbackLng: 'et',
        debug: true,
        interpolation: {
            escapeValue: false,
        },
        backend: {
            loadPath: '/locales/{{lng}}.json',
        },
    });
i18next.changeLanguage('et');

const ProtectedRoute = ({ session, redirectPath = '/', children }) => {
    if (!(session?.user ?? false)) {
        return <Navigate to={redirectPath} replace />;
    }

    return children;
};

ProtectedRoute.propTypes = {
    session: PropTypes.shape({
        user: PropTypes.object,
    }),
    redirectPath: PropTypes.string,
    children: PropTypes.node.isRequired,
};

const root = ReactDOM.createRoot(document.getElementById('root'));

const AppRoutes = () => {
    const { session, loginPage } = useUser();
    const { i18n } = useTranslation();

    return (

        <ConfigProvider locale={i18n.language}>
            {session && <>
                {!loginPage && <HeaderComponent />}
                <Routes>
                    <Route path="/" element={<Main />} />
                    <Route path="/login" element={<Login />} />
                    <Route
                        path="/dashboard"
                        element={
                            <ProtectedRoute session={session}>
                                <Dashboard />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/add"
                        element={
                            <ProtectedRoute session={session}>
                                <Add />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/courses"
                        element={
                            <ProtectedRoute session={session}>
                                <Courses />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/studyplan/:id"
                        element={
                            <ProtectedRoute session={session}>
                                <StudyPlanDetail />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/course/:id"
                        element={
                            <ProtectedRoute session={session}>
                                <CourseDetail />
                            </ProtectedRoute>
                        }
                    />
                </Routes>
                {!loginPage && <FooterComponent className="mt-5" />}
            </>}
        </ConfigProvider>
    );
};

root.render(
    <BrowserRouter>
        <I18nextProvider i18n={i18next}>
            <AlertProvider>
                <UserProvider>
                    <AppRoutes />
                </UserProvider>
            </AlertProvider>
        </I18nextProvider>
    </BrowserRouter>
);