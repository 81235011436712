import React from 'react';
import { Alert } from 'taltech-styleguide'; 
import PropTypes from 'prop-types';

const CustomAlert = ({ message, variant, onClose }) => (
  <Alert dismissible onClose={onClose} variant={variant} className="position-absolute w-100 top-0">
    {message}
  </Alert>
);

CustomAlert.propTypes = {
  message: PropTypes.string.isRequired, 
  variant: PropTypes.oneOf(['warning', 'info', 'success', 'danger']),
  onClose: PropTypes.func.isRequired, 
};

CustomAlert.defaultProps = {
  variant: 'warning',
};

export default CustomAlert;