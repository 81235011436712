import React from 'react';
import { Header, ButtonGroup, TTNewButton } from 'taltech-styleguide';
import { useUser } from '../store/UserContext';
import { makeGetRequest } from "../apiRequests";
import LinkWrapper from './LinkWrapper';
import { useTranslation } from "react-i18next";
import { useAlert } from '../store/AlertContext';

const HeaderComponent = () => {
    const { logoutUser, session } = useUser();
    const { t, i18n } = useTranslation();
    const { showAlert } = useAlert();

    const handleLogOut = async () => {
        try {
            await makeGetRequest('/logout');
            logoutUser();
        } catch (error) {
            showAlert(error.toString());
        }
    }

    const changeLanguage = () => {
        if (i18n.language === 'et') {
            i18n.changeLanguage("en");
        } else {
            i18n.changeLanguage("et");
        }
    }

    const changeLanguageOnMain = (lang) => { 
        i18n.changeLanguage(lang);
    };

    const sessionUser = session.user;
    let profile;
    let headerLinks;

    const renderLangSwitchButtons = () => {
        return (
            <ButtonGroup>
                <TTNewButton
                    className={i18n.language === 'en' ? 'fw-bold' : ''}
                    variant="tertiary"
                    onClick={() => changeLanguageOnMain('en')}>
                    ENG
                </TTNewButton>
                <span className='divider'>|</span>
                <TTNewButton
                    className={i18n.language === 'et' ? 'fw-bold' : ''}
                    variant="tertiary"
                    onClick={() => changeLanguageOnMain('et')}>
                    EST
                </TTNewButton>
            </ButtonGroup>
        );
    };

    if (sessionUser) {
        profile = {
            onLogout: handleLogOut,
            onLanguageSwitch: changeLanguage,
            profile: {
                firstName: sessionUser.first_name + ' ' + sessionUser.last_name,
                lastName: t('PROFILE.ROLE'),
                photo: '/profile.png'
            }
        }
    }

    if (sessionUser) {
        headerLinks = [
            {
                active: true,
                children: t('HEADER.TAIENDUSOPE'),
                items: [
                    {
                        children: t('HEADER.DASHBOARD'),
                        to: '/dashboard'
                    },
                    {
                        children: t('HEADER.STUDY_PROGRAMS'),
                        to: '/add'
                    },
                    {
                        children: t('HEADER.COURSES'),
                        to: '/courses'
                    }
                ]
            },
            {
                active: false,
                children: renderLangSwitchButtons()
            }
        ];
    } else {
        headerLinks = [
            {
                active: true,
                children: t('HEADER.TAIENDUSOPE'),
            },
            {
                active: false,
                children: renderLangSwitchButtons()
            }
        ];
    };

    return (
        <Header profile={profile} links={headerLinks} linkAs={LinkWrapper}>
        </Header>
    );
};

export default HeaderComponent;