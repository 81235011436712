import { useEffect, useState } from "react";
import { Loader, TTNewContainer, TTNewCard, TTNewCardContent, AdvancedTable, Heading, Icon, TTNewButton, FilterBar } from 'taltech-styleguide';
import { makeGetRequest } from "../apiRequests";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAlert } from '../store/AlertContext';

const Courses = () => {
    const [courses, setCourses] = useState([]);
    const [studyFields, setStudyFields] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { showAlert } = useAlert();
    const [selectedStudyFieldCodes, setSelectedStudyFieldCodes] = useState([]);

    // the function that fetches data of all courses
    useEffect(() => {
        const makeRequest = async () => {
            try {
                const coursesResponse = await makeGetRequest('/course');
                const courses = await coursesResponse.data;
                setCourses(courses);
                console.log(courses);
                setLoading(false);
            } catch (error) {
                showAlert(error.toString());
                console.log(error);
                setLoading(false);
            }
        };
        makeRequest();

    }, [showAlert]);

    // the function that fetches all study fields that will be used in filterbars
    useEffect(() => {
        const makeRequest = async () => {
            try {
                const studyfieldsResponse = await makeGetRequest('/studyfield');
                const studyfields = await studyfieldsResponse.data;
                setStudyFields(studyfields);
                setLoading(false);
            } catch (error) {
                showAlert(error.toString());
                setLoading(false);
            }
        };
        makeRequest();

    }, [showAlert]);

    const viewDetail = (rowData) => {
        navigate('/course/' + rowData.id);
    }

    const renderMoreButtonsOnHover = (rowData) => {
        return (
            <div className="d-flex justify-content-end">
                <Icon
                    name="more_vert"
                />
                <div className="more-actions-buttons">
                    <TTNewButton variant="link" onClick={() => viewDetail(rowData)}>
                        {t('ADVANCED_TABLE.VIEW_DETAILS')}
                    </TTNewButton>
                </div>
            </div>
        );
    }

    const handleFilterChange = (selectedOptions) => {
        const selectedValues = selectedOptions.map(option => option.value);
        setSelectedStudyFieldCodes(selectedValues);
    };

    const colors = ['#A1C67D', '#AA1352', '#FFC75A', '#A84CD3', '#51BFD3', '#E87839', '#124BBA', '#9092AD'];
    let colorIndex = 0;
    const colorAssignments = () => {
        colorIndex = (colorIndex + 1) % colors.length;
        return colors[colorIndex];
    };

    const filteredCourses = courses.filter(course =>
        selectedStudyFieldCodes.length === 0 || selectedStudyFieldCodes.includes(course.study_field_code)
    );

    return (
        <TTNewContainer>
            <Heading className="mb-5"
                color="primary"
                visual="display-2">
                {t('COURSES.TITLE')}
            </Heading>
            {loading ? (
                <Loader fullScreen center size="md" />
            ) : (
                <TTNewCard padding={1}>
                    <TTNewCardContent>
                        <FilterBar
                            allToggle="Kõik"
                            className="rounded-pill mb-5"
                            onChange={handleFilterChange}
                            value={selectedStudyFieldCodes}
                            options={studyFields.map(field => ({
                                value: field.code,
                                label: field.name_est,
                                color: colorAssignments()
                            }))}
                        >
                        </FilterBar>
                        <AdvancedTable
                            columns={[
                                {
                                    dataField: 'id',
                                    text: t('COURSES.COLUMNS.ID')
                                },
                                {
                                    dataField: 'code',
                                    text: t('COURSES.COLUMNS.CODE')
                                },
                                {
                                    dataField: 'name_est',
                                    text: t('COURSES.COLUMNS.NAME_EST')
                                },
                                {
                                    dataField: 'volume',
                                    text: t('COURSES.COLUMNS.VOLUME')
                                },
                                {
                                    dataField: 'semester',
                                    text: t('COURSES.COLUMNS.SEMESTER'),
                                    formatter: (cellContent) => {
                                        return t(`SEMESTER.${cellContent}`);
                                    }
                                },
                                {
                                    dataField: 'language',
                                    text: t('COURSES.COLUMNS.LANGUAGE'),
                                    formatter: (cellContent) => {
                                        return t(`LANGUAGES.${cellContent}`);
                                    }
                                },
                                {
                                    dataField: 'more',
                                    text: t('DASHBOARD.COLUMNS.ACTIVITIES'),
                                    formatter: (cell, row) => renderMoreButtonsOnHover(row)
                                }

                            ]}
                            data={filteredCourses}
                            keyField="id"
                            rowClasses="advanced-table-row"
                            hideFilterBar
                            onTableChange={function Na() { }}
                            pagination={{
                                sizePerPage: 20,
                                sizePerPageList: [
                                    {
                                        text: '20',
                                        value: 20
                                    },
                                    {
                                        text: '40',
                                        value: 40
                                    },
                                    {
                                        text: '60',
                                        value: 60
                                    },
                                    {
                                        text: '100',
                                        value: 100
                                    }
                                ]
                            }}
                        />
                    </TTNewCardContent>
                </TTNewCard>
            )}
        </TTNewContainer>
    )
};

export default Courses;